#title-bar {
    color: white;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    top: 0;
    font-size: 30px;
    width: 100%;
    height: 5rem;
    margin-bottom: 1rem;
    position: relative;
    overflow: hidden;
}

#title-bar::before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background-image: url("../../assets/forest.jpeg");
    background-size: cover;
    filter: blur(2px);
    z-index: -1;
}
